<template>
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <v-alert v-if="alert" :type="alert.status" dismissible text>
      {{ alert.message }}
    </v-alert>
    <v-card elevation="2" class="pa-5 mt-5">
      <v-card-title>
        施工事例一覧
        <v-col class="text-right">
          <v-btn small color="primary" :to="{ name: 'CreatePost' }">
            事例の新規追加
          </v-btn>
        </v-col>
      </v-card-title>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="posts"
          :options.sync="options"
          :server-items-length="totalPosts"
          :loading="loading"
          @click:row="openPostEditPage"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, 100]
          }"
          :items-per-page="50"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn
                x-small
                icon
                color="blue"
                class="mr-2"
                :to="{ name: 'EditPost', params: { id: item.id } }"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                x-small
                icon
                color="blue"
                class="mr-2"
                @click.stop="showFrontendPostView(item)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.title="{ item }">
            <div class="d-flex py-3">
              <v-img
                :src="item.featured_image"
                :width="100"
                :max-width="100"
                :max-height="70"
                :height="70"
                :eager="true"
                class="mr-3"
                transition="fade-transition"
              >
              </v-img>
              <div>
                <div class="grey--text">{{ item.created_date }}</div>
                <div class="">{{ item.title }}</div>
              </div>
            </div>
          </template>
          <template v-slot:item.categories="{ item }">
            <span
              v-for="category in item.categories"
              :key="category.id"
              class="d-block"
            >
              {{ category.name }}
            </span>
          </template>
          <template v-slot:item.status="{ item }">
            <span v-if="item.status == 'ACTIVE'" class="green--text"
              >公開中</span
            >
            <span v-else-if="item.status == 'INACTIVE'" class="red--text"
              >非公開</span
            >
            <span v-else-if="item.status == 'DRAFT'" class="grey--text"
              >下書き</span
            >
            <span v-else class="grey--text">{{ item.status }}</span>
            <v-switch
              class="mt-0"
              v-model="item.post_status"
              @click.stop="postToggleStatus(item)"
            ></v-switch>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import PostService from '@/services/api-methods'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: '施工事例管理',
          disabled: false,
          to: { name: 'Post' },
          exact: true
        },
        {
          text: '施工事例一覧',
          disabled: true,
          to: { name: 'Post' },
          exact: true
        }
      ],
      messages: null,
      totalPosts: 0,
      posts: [],
      loading: true,
      options: {
        sortBy: ['id'],
        sortDesc: [true]
      },
      headers: [
        {
          text: 'ID',
          align: 'start',
          // sortable: false,
          value: 'id'
        },
        { text: 'タイトル', value: 'title' },
        { text: 'カテゴリー', value: 'categories' },
        { text: 'ステータス', value: 'status', sortable: false },
        { text: '閲覧数', value: 'views' },
        { text: 'アクション', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    options: {
      handler() {
        this.getPostFromAPI()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['alert']),
    reqParams() {
      return {
        itemsPerPage: this.options.itemsPerPage,
        page: this.options.page,
        sortBy: this.options.sortBy[0],
        sortDesc: this.options.sortDesc[0]
      }
    }
  },
  mounted() {
    if (this.alert != null) {
      setTimeout(() => {
        this.$store.commit('resetAlert')
      }, 5000)
    }
  },
  methods: {
    postToggleStatus(item) {
      console.log(item.status)
      let toggleStatus
      if (item.status == 'ACTIVE') {
        toggleStatus = 'INACTIVE'
      } else {
        toggleStatus = 'ACTIVE'
      }
      PostService.post('/update-post-status', {
        status: toggleStatus,
        id: item.id
      })
        .then(res => {
          let response = res.data
          if (response.status == 'success') {
            item.status = toggleStatus
          } else {
            this.$swal.fire('アクションなし！', response.message, 'error')
          }
        })
        .catch(err => {
          this.$swal.fire(
            'アクションなし！',
            'APIエラーが発生しました！',
            'error'
          )
        })
    },
    getPostFromAPI() {
      this.loading = true
      PostService.get('/posts', this.reqParams)
        .then(res => {
          this.posts = res.data.data
          if (this.posts && this.posts.length > 0) {
            this.posts = this.posts.map(item => {
              item.post_status = item.status == 'ACTIVE' ? true : false
              return item
            })
          }
          this.totalPosts = res.data.total
          this.loading = false
        })
        .catch(err => {
          this.$swal.fire('アクションなし！', err, 'error')
        })
    },
    openPostEditPage(row) {
      this.$router.push({ name: 'EditPost', params: { id: row.id } })
    },
    showFrontendPostView(item) {
      window.open(
        `${process.env.VUE_APP_URL}work/${item.id}/${
          item.area ? item.area : '福岡県'
        }/${item.slug}`,
        '_blank'
      )
    }
  }
}
</script>
