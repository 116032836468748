var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs},scopedSlots:_vm._u([{key:"divider",fn:function(){return [_c('v-icon',[_vm._v("mdi-chevron-right")])]},proxy:true}])}),(_vm.alert)?_c('v-alert',{attrs:{"type":_vm.alert.status,"dismissible":"","text":""}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]):_vm._e(),_c('v-card',{staticClass:"pa-5 mt-5",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" 施工事例一覧 "),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"small":"","color":"primary","to":{ name: 'CreatePost' }}},[_vm._v(" 事例の新規追加 ")])],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.posts,"options":_vm.options,"server-items-length":_vm.totalPosts,"loading":_vm.loading,"footer-props":{
          'items-per-page-options': [10, 25, 50, 100]
        },"items-per-page":50},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openPostEditPage},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"blue","to":{ name: 'EditPost', params: { id: item.id } }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","icon":"","color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.showFrontendPostView(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)],1)]}},{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex py-3"},[_c('v-img',{staticClass:"mr-3",attrs:{"src":item.featured_image,"width":100,"max-width":100,"max-height":70,"height":70,"eager":true,"transition":"fade-transition"}}),_c('div',[_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(item.created_date))]),_c('div',{},[_vm._v(_vm._s(item.title))])])],1)]}},{key:"item.categories",fn:function(ref){
        var item = ref.item;
return _vm._l((item.categories),function(category){return _c('span',{key:category.id,staticClass:"d-block"},[_vm._v(" "+_vm._s(category.name)+" ")])})}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.status == 'ACTIVE')?_c('span',{staticClass:"green--text"},[_vm._v("公開中")]):(item.status == 'INACTIVE')?_c('span',{staticClass:"red--text"},[_vm._v("非公開")]):(item.status == 'DRAFT')?_c('span',{staticClass:"grey--text"},[_vm._v("下書き")]):_c('span',{staticClass:"grey--text"},[_vm._v(_vm._s(item.status))]),_c('v-switch',{staticClass:"mt-0",on:{"click":function($event){$event.stopPropagation();return _vm.postToggleStatus(item)}},model:{value:(item.post_status),callback:function ($$v) {_vm.$set(item, "post_status", $$v)},expression:"item.post_status"}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }